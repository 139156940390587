import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { toast } from "react-toastify";
// import { ReactComponent as Kite } from "../../Images/kite.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/auth";
import { addContacts } from "../../config/elasticEmail";
import { handleFormatPhoneNumber } from "../../hooks";
import { WaitListModal, ViewDetailModal } from "../custom";

const handleGetDateRange = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  const formattedStartDate = startDate.format("Do MMM");
  let formattedEndDate = endDate.format("Do MMM");
  if (!startDate.isSame(endDate, "month")) {
    formattedEndDate = endDate.format("Do MMM YYYY");
  }
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const SectionThree = ({ travelPackages }) => {
  const convertedId = (id) => {
    return id.split(" ").join("-");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [authorName, setAuthorName] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorPhone, setAuthorPhone] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [packageTitle, setPackageTitle] = useState("");
  const [packageId, setPackageId] = useState("");
  const [loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_ELASTIC_EMAIL_API_KEY_2;
  const apiBaseUrl = process.env.REACT_APP_ELASTIC_EMAIL_API_BASE_URL;

  const addToFirebase = async (Details) => {
    const updatedPhoneNumber = handleFormatPhoneNumber(
      Details.selectedCountryCode,
      Details.authorPhone
    );

    // Reference to the waitlist collection
    const waitlistRef = collection(db, "waitlist");

    // Add a new document (subscriberDetails) to the subscribers collection
    await addDoc(waitlistRef, {
      authorName: Details.authorName,
      authorEmail: Details.authorEmail,
      authorPhone: updatedPhoneNumber,
      createdAt: serverTimestamp(),
      packageTitle: Details.packageTitle,
    });
  };

  const handleJoinWaitList = async (Details) => {
    setLoading(true);

    if (!Details.authorName || !Details.authorEmail) {
      toast.error("Please fill all fields");
      setLoading(false);
      return;
    }

    const url = `${apiBaseUrl}/lists`;
    const headers = {
      "Content-Type": "application/json",
      "X-ElasticEmail-ApiKey": apiKey,
    };

    const requestBody = {
      ListName: Details.packageTitle,
      AllowUnsubscribe: true,
      Emails: [Details.authorEmail],
    };

    const contacts = [
      {
        Email: Details.authorEmail,
        FirstName: Details.authorName.split(" ")[0],
        LastName: Details.authorName.split(" ")[1],
      },
    ];

    const listNames = [Details.packageTitle];

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // console.error('Error data:', errorData);

        // Check if the error is "A list with the given name already exists"
        if (errorData.Error === "A list with the given name already exists.") {
          // Add the contact to the existing list
          addContacts(contacts, listNames);
          addToFirebase(Details);
          // console.log('Added to existing list:', Details.packageTitle);
          toast.success("Added to waitlist");
        } else {
          // Handle other errors
          console.error(`HTTP error! Status: ${response.status}`);
          toast.error("Error adding to waitlist");
        }
      } else {
        const data = await response.json();
        // Add the contact to the newly created list
        addContacts(contacts, listNames);
        addToFirebase(Details);
        console.log("Added to waitlist:", data);
        toast.success("Added to waitlist");
      }
    } catch (error) {
      // Log unexpected errors
      console.error("Unexpected error:", error);
      toast.error("Unexpected error adding to waitlist");
    } finally {
      setLoading(false);
      setIsOpen(false);
      setAuthorName("");
      setAuthorEmail("");
      setPackageTitle("");
      setPackageId("");
    }
  };

  const handleMouseDown = (e, text) => {
    // Set a timer to trigger after 1 second (adjust as needed)
    const timer = setTimeout(() => {
      // Copy the link to the clipboard
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Alert or perform any action after successful copy
          toast.success("Link copied to clipboard");
        })
        .catch((error) => {
          console.error("Failed to copy link: ", error);
          toast.error(error);
        });
    }, 1000); // 1000 milliseconds = 1 second

    // Clear the timer if mouse is released before 1 second
    e.target.addEventListener("mouseup", () => clearTimeout(timer));
  };

  // For touch devices, handle touch events
  const handleTouchStart = (e, text) => {
    // Set a timer to trigger after 1 second (adjust as needed)
    const timer = setTimeout(() => {
      // Copy the link to the clipboard
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Alert or perform any action after successful copy
          toast.success("Link copied to clipboard");
        })
        .catch((error) => {
          console.error("Failed to copy link: ", error);
          toast.error(error);
        });
    }, 1000); // 1000 milliseconds = 1 second

    // Clear the timer if touch is released before 1 second
    e.target.addEventListener("touchend", () => clearTimeout(timer));
  };

  const [selectedPackData, setSelectedPackData] = useState(null);
  const [isPackModalOpen, setIsPackModalOpen] = useState(false);

  const handleViewDetails = (pack) => {
    setSelectedPackData(pack);
    setIsPackModalOpen(true);
  };

  return (
    <div className="w-screen font-family font-inter py-16 px-10 xl:px-0 bg-[#ffffff] flex flex-col gap-14 items-center font-inter justify-center sm:py-10 sm:gap-6">
      <h3 className="text-gray-900  font-bold text-4xl Aceh xl:text-4xl sm:text-2xl">
        Top Selling Travel Packages
      </h3>
      <div className="w-11/12 xl:w-[calc(100vw-60px)] md:w-[calc(100vw-40px)]">
        <Swiper
          // breakpoints={breakpoints}
          slidesPerView={"auto"}
          // spaceBetween={10}
          //navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper w-full"
        >
          {travelPackages
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .slice(0, 10)
            .map((travelPackage) => {
              return (
                <SwiperSlide
                  key={travelPackage?.id}
                  className="max-w-max sm:h-max"
                >
                  <div className="pr-3.5 w-max pb-3">
                    <div
                      className="relative flex flex-col w-[360px] rounded-xl border border-[#] shadow-md z-10 xl:w-[300px] lg:w-[320px] md:w-[280px] sm:w-[300px]"
                      onMouseDown={(e) =>
                        handleMouseDown(
                          e,
                          travelPackage.isWaitList
                            ? `https://explore.crystalveey.com/waitlist-share/${convertedId(
                                travelPackage?.id
                              )}`
                            : `https://explore.crystalveey.com/grouptrips/${convertedId(
                                travelPackage?.id
                              )}`
                        )
                      }
                      onTouchStart={(e) =>
                        handleTouchStart(
                          e,
                          travelPackage.isWaitList
                            ? `https://explore.crystalveey.com/waitlist-share/${convertedId(
                                travelPackage?.id
                              )}`
                            : `https://explore.crystalveey.com/grouptrips/${convertedId(
                                travelPackage?.id
                              )}`
                        )
                      }
                    >
                      <div
                        className={`absolute top-3 left-3 p-1 px-2 min-w-[100px] text-center bg-gradient-to-br  text-white text-sm font-semibold rounded-xl flex gap-1.5 justify-center items-center ${
                          travelPackage.slotsLeft === 0
                            ? "from-[#FF5630] to-red-700"
                            : travelPackage.slotsLeft <= 5
                            ? "from-[rgb(230,202,102)] to-red-400"
                            : "from-[#FFC223] to-[rgb(230,202,102)]"
                        }`}
                      >
                        {travelPackage.slotsLeft === 0
                          ? "Sold Out! 😥"
                          : travelPackage.slotsLeft <= 5
                          ? "Hurry! Only" +
                            " " +
                            travelPackage.slotsLeft +
                            " " +
                            "slots left! 🔥"
                          : // eslint-disable-next-line no-useless-concat
                            travelPackage.slotsLeft + " " + "slots left! 🤩"}
                      </div>
                      <img
                        src={travelPackage.images.imageOne}
                        alt={travelPackage.title + "image"}
                        className="w-full h-60 object-cover rounded-t-xl"
                        width={360}
                        height={240}
                      />
                      <div className="w-full rounded-b-xl border border-gray-200 bg-white p-5 pt-8 flex flex-col gap-5 relative">
                        <div className="flex justify-between items-center">
                          <h4 className="font-bold text-base text-black one-line-text">
                            {travelPackage.title}
                          </h4>
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-1.5 items-center">
                              {/* <Image src={calendarSVG} alt="calendar" className="w-5 h-5" /> */}
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className="w-5 h-5 text-[#00AEEF]"
                              />
                              <p className="text-sm text-gray-500">
                                {handleGetDateRange(
                                  travelPackage.startDate,
                                  travelPackage.endDate
                                )}
                              </p>
                            </div>
                            {travelPackage.duration && (
                              <div className="flex gap-1 items-center text-[#000000] text-sm font-semibold">
                                {travelPackage.duration} days
                              </div>
                            )}
                          </div>
                          {travelPackage.isWaitList ? (
                            <button
                              className="flex justify-center gap-3 w-full items-center bg-[#000000] text-white font-semibold px-4 py-3 rounded-xl hover:bg-[#212B36] transition ease-in-out duration-300"
                              onClick={() => {
                                setIsOpen(true);
                                setPackageTitle(travelPackage.title);
                                setPackageId(travelPackage?.id);
                              }}
                            >
                              {/* <Image src={viewSVG} alt="view" className="w-5 h-5" /> */}
                              <FontAwesomeIcon
                                icon={faEye}
                                className="w-5 h-5"
                              />
                              <p className=" text-sm">Join Waitlist</p>
                            </button>
                          ) : (
                            // <Link
                            //   href={`/grouptrips/${convertedId(
                            //     travelPackage?.id
                            //   )}`}
                            // >
                            <button
                              className="flex justify-center items-center gap-3 w-full bg-[#000000] text-white font-semibold px-4 py-3 rounded-xl hover:bg-[#212B36] transition ease-in-out duration-300"
                              onClick={() => handleViewDetails(travelPackage)}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                className="w-5 h-5"
                              />
                              <p className=" text-sm">View Details</p>
                            </button>
                            // </Link>
                          )}
                        </div>
                        <div className="absolute -top-7 left-[115px] bg-white p-3.5 border border-gray-200 rounded-full font-semibold text-sm text-[#00AEEF] xl:left-[90px] lg:left-[99px] md:left-[80px] sm:left-[89px]">
                          {travelPackage.price
                            ? travelPackage?.currency === "NGN"
                              ? travelPackage.price.toLocaleString("en-NG", {
                                  style: "currency",
                                  currency: "NGN",
                                })
                              : travelPackage.currency === "USD"
                              ? travelPackage.dollarPrice.toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )
                              : travelPackage.poundPrice.toLocaleString(
                                  "en-GB",
                                  { style: "currency", currency: "GBP" }
                                )
                            : "Coming Soon!"}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      <WaitListModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleJoinWaitList={handleJoinWaitList}
        loading={loading}
        authorName={authorName}
        authorEmail={authorEmail}
        setAuthorName={setAuthorName}
        setAuthorEmail={setAuthorEmail}
        authorPhone={authorPhone}
        setAuthorPhone={setAuthorPhone}
        selectedCountryCode={selectedCountryCode}
        setSelectedCountryCode={setSelectedCountryCode}
        packageTitle={packageTitle}
        packageId={packageId}
      />
      <ViewDetailModal
        isOpen={isPackModalOpen}
        setIsOpen={setIsPackModalOpen}
        data={selectedPackData}
      />
    </div>
  );
};
